import React from "react"

const Header = () => (
  <div className="header">
    <h1>
      <a href="https://blog.suprada.com" alt="Suprada's Blog">
        Suprada&apos;s Blog
      </a>
    </h1>
    {/* <Menu /> */}
  </div>
)

export default Header
