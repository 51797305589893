import React from "react"
import { css } from "@emotion/core"
import Header from "../components/header"
import colors from "../styles/colors"

const AboutStyle = css`
  color: ${colors.teal};
`

const About = () => (
  <div css={AboutStyle}>
    <Header headerText="About the Year of the Turtle" />
    <p>Yeah, the year of the turtle</p>
  </div>
)

export default About
